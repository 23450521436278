<div class="card text-black mt-3 mb-3">
    <div class="card-header">
        <div class="row">
            <div class="col col-lg-3">
                <i class="fa {{ icon }} fa-2x color-{{ bgClass }} mt-3"></i>
            </div>
            <div class="col col-lg-9 text-right">
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{label1}}</th>
                        <th scope="col">{{label2}}</th>
                        <th scope="col">{{label3}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{count1}}</td>
                        <td>{{count2}}</td>
                        <td>{{count3}}</td>
                      </tr>
                
                    </tbody>
                  </table>
               
            </div>
        </div>
        
    </div>
</div>
